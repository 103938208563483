<template >
  <v-row style="position: relative; min-width: 0px; display: block;" justify="center" class="mx-4">
    <v-hover v-slot:default="{ hover }">
      <div class="mx-auto">
        <v-avatar
          v-if="img && !!img.URL"
          :size="customWidth"
          tile>
          <v-img :src="img.URL" contain/>
        </v-avatar>
        <Avatar v-else :username="name" :size="customWidth"/>
        <v-expand-transition>

          <div v-if="hover"
            class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal display-3 white--text mx-auto"
            :style="`height: ${img && !!img.URL ? '100%' : '100%'}; width: ${customWidth}px;`">
            <v-row no-gutters>
              <v-col cols="12">

                <v-btn
                  v-if="img"
                  style="width: 100%;height:100%"
                  color="white"
                  outlined
                  text
                  @click="deletePicture">
                  {{$t('common.picture.buttons.delete')}}
                </v-btn>

              </v-col>

              <v-col cols="12">
                <v-btn
                  outlined
                  text
                  color="white"
                  style="height: 100%;width: 100%;"
                  @click="openDialog">
                  <!--v-icon>create</v-icon-->
                  {{$t('common.picture.buttons.edit')}}
                </v-btn>
              </v-col>

            </v-row>

          </div>
        </v-expand-transition>
      </div>
    </v-hover>
    <ChangePictureDialog
      v-if="showChangePictureDialog"
      :value="showChangePictureDialog"
      :UPDATE_API="UPDATE_API"
      @close="(needRefresh) => closeDialog(needRefresh)"
      />
  </v-row>
</template>
<script>
  // Node modules
  import Avatar from 'vue-avatar'

  export default {
    name: "DisplayPictureEditable",

    mixins: [],

    props: {
      img: {type: Object, default: () => {}},
      customWidth: {type: Number, default: 100},
      name: {type: String, default: null},
      DELETE_API: {type: String, default: null},
      UPDATE_API: {type: String, default: null}
    },

    components: {
      Avatar,
      ChangePictureDialog: () => import("@/components/Common/Picture/ChangePictureDialog")
    },

    data() {
      return {
        showChangePictureDialog: false
      }
    },

    computed: {
    },

    methods: {
      openDialog() {
        this.showChangePictureDialog = true;
      },

      closeDialog(needRefresh = false) {
        if (needRefresh) {
          this.$emit("changed");
        }
        this.showChangePictureDialog = false;
      },

      deletePicture() {
        this.$http.delete(this.DELETE_API, {
          headers: {
            Authorization: "Bearer " + this.$session.get('jwt'),
            //'content-type': 'multipart/form-data'
          }
        })
        .then(res => {
          this.$emit("changed");
        })
        .catch(err => {
          this.$store.commit("toast/showError", this.$t('common.errors.500'));
        })
      }
    }
  }
</script>

<style scoped>
  .v-card--reveal
  {
    align-items: center;
    justify-content: center;
    opacity: .8;
    position: absolute;
    width: 100%;
    bottom: 0;
  }


</style>